@import "~react-image-gallery/styles/css/image-gallery.css";

#Home {
  text-align: center;
  font-family: "Poiret One";
  font-size: 16px;
  font-size: 28px;

  h2 {
    font-family: "Champignon";
    font-weight: 100;
    font-size: 54px;
    margin: 40px auto;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  .FadeIn,
  .FadeInChildren>h3,
  .FadeInChildren>a {
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .Names {
    font-weight: 600;
    font-size: 120px;
    background: linear-gradient(to right, #c677b3 0%, #c6a747 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 4px;
    opacity: 0;
  }

  #Summary>h3,
  #Summary>a {
    opacity: 0;
    animation-delay: 1s;
  }

  .Date {
    font-size: 36px;
  }

  #TopImage {
    background-image: linear-gradient(to bottom, transparent 20%, rgba(255, 252, 245, 0.9)), url("../public/images/background.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    height: calc(100vh - 32px);
  }

  #Hero,
  #Hero a {
    color: #d68b22;
  }

  #Hero {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 80px;
    font-size: 20px;
    font-weight: 600;
  }

  .Section {
    display: flex;
    margin-bottom: 8px;
  }

  .Section p {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Section .image-gallery {
    width: 240px;
  }

  #OurStory,
  #Venue,
  #Gallery {
    width: 80%;
    margin: auto;
  }

  .image-gallery {
    flex-shrink: 0;
  }

  .image-gallery-content {
    height: 100%;
  }

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: auto !important;
    width: 40% !important;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    width: 25%;
    padding: 0;
  }

  #Gallery .image-gallery-icon,
  #Venue .image-gallery-icon {
    width: 160px;
  }

  .image-gallery.fullscreen-modal {
    width: 100% !important;
    z-index: 11;
  }

  .image-gallery.fullscreen-modal .image-gallery-slide-wrapper {
    top: 50%;
    transform: translateY(-50%);

    .image-gallery-slide .image-gallery-image {
      max-height: 100dvh;
    }
  }

  .fullscreen .image-gallery-slide-wrapper {
    top: 50%;
    transform: translateY(-50%);

    .image-gallery-slide .image-gallery-image {
      max-height: 100dvh;
    }
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: unset;
  }

  #Hashtag {
    font-family: "Poiret One";
    font-size: 20px;
    font-weight: 600;
  }

  #Hashtag a {
    color: #000;
  }

  .Section .GalleryContainer {
    min-height: 336px;
  }

  #Venue .GalleryContainer {
    min-height: 60vw;
  }

  #Gallery .GalleryContainer {
    min-height: 53vw;
  }

  @media only screen and (max-width: 800px) {
    #TopImage {
      background-image: linear-gradient(to bottom, transparent 30%, rgba(255, 252, 245, 0.6) 60%), url("../public/images/background-phone.webp");
      background-position: center;
      height: calc(100vh - 32px);
    }

    #Hero {
      margin-bottom: 64px;
    }

    .Date {
      font-size: 24px;
    }

    .Names {
      font-size: 54px;
    }

    #Hero {
      font-size: 16px;
    }

    .Section {
      flex-direction: column;
      align-items: center;
    }

    #Section2 {
      flex-direction: column-reverse;
    }

    #Gallery .image-gallery-icon,
    #Venue .image-gallery-icon {
      width: 80px;
    }

    #OurStory,
    #Venue,
    #Gallery {
      width: 90%;
    }

    #Venue .GalleryContainer {
      height: 68vw;
    }

    #Gallery .GalleryContainer {
      height: 60vw;
    }
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}