@font-face {
  font-family: "Champignon";
  src: url("../public/fonts/Champignon.woff");
}

@font-face {
  font-family: "Poiret One";
  src: url("../public/fonts/PoiretOne-Regular.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

body {
  background-color: #fffcf5;
}

html,
body {
  overflow: hidden;
  height: 100%;
}

p {
  font-family: "Poiret One";
  font-size: 18px;
}

a {
  color: #bb3f8b;
}

.Placeholder {
  height: 52px;
  margin: 0;
  padding: 0;
}

.MainContent {
  position: relative;
  height: calc(100% - 52px);
  overflow: auto;
}