.InfoPage {
    max-width: 800px;
    margin: auto;
    padding: 24px;

    .Emphasis {
        color: crimson;
    }

    img {
        width: 100%;
    }

    .Cursive {
        font-family: "Champignon";
        font-size: 54px;
        line-height: 48px;
    }

    h2 {
        margin: 8px 0;
    }

    .Section {
        margin-top: 16px;
        display: block;
    }

    .SubSection {
        margin-top: 8px;
        display: block;
    }

    .Notification {
        border: 1px solid;
        border-top-color: currentcolor;
        border-right-color: currentcolor;
        border-bottom-color: currentcolor;
        border-left-color: currentcolor;
        position: fixed;
        bottom: 40px;
        left: 0;
        right: 0;
        margin: auto;
        width: 80%;
        max-width: 240px;
        text-align: center;
        padding: 4px;
        background: #f2e1c9;
        border-radius: 6px;
        border-color: #4a4a4a;
        visibility: visible;
        opacity: 1;
        transition: opacity 1s linear;

        &.Hidden {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s 1s, opacity 1s linear;
        }
    }

    .CopyField {
        border: none;
        background: transparent;
        font-size: 16px;
        width: 240px;
        margin: 0;
        padding: 0;
    }

    @media only screen and (max-width: 800px) {
        .SubSection {
            margin-top: 8px;
            display: block;
            display: flex;
            flex-direction: column;
        }

        .CopyField {
            width: 100%;
        }
    }
}