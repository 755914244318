#Navigation {
    font-family: "Poiret One";
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style: none;
    box-sizing: border-box;
    transition: height 0.15s ease-out;
    overflow: hidden;
    border-bottom: solid 1px;
    margin: 0;
    padding: 16px;
    position: fixed;
    top: 0;
    left: 0;
    background: #fffcf5;
    width: 100%;
    z-index: 10;
}

#Navigation li {
    margin: 0 16px;
}

#Navigation li a {
    text-decoration: none;
    font-weight: 600;
    color: #d68b22;
    padding: 4px;
}

@media(hover: hover) and (pointer: fine) {
    #Navigation li a:hover {
        background-color: #f2cdcd;
        color: #fff;
    }
}

#Navigation .NavIcon {
    display: none;
}

#Navigation .NavIcon button {
    border: none;
    background: none;
}

.NavIcon img {
    width: 16px;
}

@media only screen and (max-width: 800px) {
    #Navigation {
        flex-direction: column;
    }

    #Navigation.Collapsed {
        height: 52px;
    }

    #Navigation {
        height: 200px;
    }

    #Navigation li {
        margin: 4px 0;
    }

    #Navigation .NavIcon {
        display: inline-block;
    }

    #Navigation .Collapsable {
        display: inline-block;
        width: max-content;
    }

    #Navigation.Collapsed .Collapsable {
        display: none;
    }

    #Navigation #Menu {
        display: none;
    }

    #Navigation.Collapsed #Menu {
        display: inline-block;
    }
}