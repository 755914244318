#PhotoShare {
    width: 90vw;
    margin: auto;

    h1 {
        font-family: "Champignon";
        font-size: 42px;
        text-align: center;
    }

    #PhotoGrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(160px, auto));
        width: 100%;
        row-gap: 4px;

        .GridItem {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            width: 160px;
            height: 160px;
            margin: auto;
            background-color: #f4f2f2;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                background: url('../public/images/image-placeholder.png');
                background-repeat: no-repeat;
                background-size: 80px 80px;
                background-position: center;
            }

            .Loading {
                opacity: 0.6;
            }
        }

        #ImageUploader {

            img {
                background-image: none;
                width: 60%;
                height: 60%;
            }

            #UploadImage {
                display: none;
            }
        }
    }
}

.Loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.PhotoView-Slider__toolbarIcon {
    width: 44px;
    height: 44px;
}