#Schedule {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 0px 8px gray;
    height: 560px;

    h2 {
        text-align: center;
    }

    ul {
        display: table;
        margin: auto;
        padding: 0;
        margin-top: 24px;
        font-weight: 500;

        li {
            list-style: none;
            display: table-row;

            .Cell {
                display: table-cell;
                padding: 8px 16px;
                vertical-align: middle;
            }

            .Icon {
                width: 40px;
                height: 40px;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    #Schedule {
        height: 100%;
    }
}