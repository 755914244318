#Loading {
    float: left;
    background-color: #f3e0bd;
    border-radius: 50%;
    padding: 32px;
    margin: auto;
    position: fixed;
    width: 256px;
    height: 256px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: rotate 30s infinite;

    img {
        width: 256px;
    }
}

@keyframes rotate {
    from {
        rotate: 0deg;
    }

    to {
        rotate: 360deg;
    }
}