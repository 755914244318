#RSVP {
    width: 80%;
    min-height: 100%;
    margin: auto;
    max-width: 800px;
    background-color: rgb(249, 233, 243);
    box-shadow: 0 0 8px #311b0b;

    #InviteBody {
        padding: 32px;
    }

    #HeaderImage {
        background-image: linear-gradient(to bottom, transparent 60%, rgba(249, 233, 243, .9)), url("../public/images/rsvp-desktop.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 533px;
    }

    .RadioOptions {
        display: inline-block;

        label {
            margin-right: 8px;
        }
    }

    h2 {
        font-family: "Champignon";
        font-size: 56px;
        margin: 8px 0;
        line-height: 48px;
        margin-bottom: 32px;
    }

    label {
        margin-top: 8px;
        opacity: 1;
        animation: fadeInFromNone 0.5s ease-out;
        font-family: "Poiret One";
        font-weight: bold;
    }

    .RadioOptions label {
        font-weight: normal;
    }

    p {
        font-size: 16px;
        margin: 8px 0;
    }

    form {
        margin: auto;
    }

    form,
    .PersonInputs {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
    }

    #RequiresTransport {
        margin-bottom: 32px;
    }

    input[type="text"] {
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin-top: 8px;
    }

    input[type="text"],
    label {
        font-size: 16px;
    }

    button[type="submit"],
    button[type="button"] {
        width: 160px;
        margin: auto;
        margin-bottom: 8px;
        padding: 8px;
        background: white;
        border: 2px solid crimson;
        color: crimson;
        border-radius: 4px;
        font-weight: bold;

        &:disabled {
            border: 2px solid lightgray;
            color: lightgray;
        }
    }

    .Hidden {
        display: none;
        opacity: 0;
    }

    .PersonName {
        font-weight: bold;
    }

    .Bold {
        font-weight: bold;
    }
}

@media only screen and (max-width: 800px) {
    #RSVP {
        width: 100%;

        #HeaderImage {
            height: 360px;
            background-image: linear-gradient(to bottom, transparent 70%, rgba(249, 233, 243, .9)), url("../public/images/rsvp-mobile.webp");
        }
    }
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}